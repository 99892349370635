
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "kt-account",

  setup() {
    // const isMultipleMedia = ref('image-single')
    const isMultipleMedia = ref(true);
    const store = useStore();

    return {
      isMultipleMedia,
    };
  },
});
